<template>
  <div>
    <!-- eslint-disable -->
  <span>
    <div ref='content'>
    <b-row
      v-if="!invoiceId"
      class="invoice-preview"
    >
      <b-col
        cols="12"
      >
        <b-card>
          <h6>No current invoice available.</h6>
        </b-card>
      </b-col>
    </b-row>
    <section
      v-if="formData.systemSettings"
      class="invoice-preview-wrapper"
    >
      <b-row
        class="invoice-preview"
      >
        <b-col
          v-if="invoiceMessage"
          cols="12"
        >
          <b-alert
            show
            variant="warning"
            dismissible
          >
            <h4 class="alert-heading">
              <feather-icon
                icon="InfoIcon"
                size="18"
              /> Notice
            </h4>
            <p style="padding:10px 10px 0px 50px">
              {{ invoiceMessage }}
            </p>
            <hr>
          </b-alert>
        </b-col>

        <!-- Col: Left (Invoice Container) -->
        <b-col
          id="printMe"
          ref="printMe"
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body
              class="invoice-padding pb-0"
            >

              <div class="d-flex justify-content-between flex-md-row flex-column mt-0">

                <!-- Header: Left Content -->
                <div class="d-flex">
                  <div class="logo-wrapper">
                    <b-img
                      :src="require('@/assets/images/logo/tm-partial-logo-2.png')"
                      alt="logo"
                    />
                    <h3 class="text-primary invoice-logo ml-0">
                      {{ appName }}
                    </h3>
                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    {{ formData.invoiceNo }}
                  </h4>

                  <div class="invoice-date-wrapper mb-0">
                    <p class="invoice-date-title">
                      Date Issued:
                    </p>
                    <p class="invoice-date">
                      {{ formData.dateIssued }}
                    </p>
                  </div>

                  <div
                    v-if="formData.dueDate"
                    class="invoice-date-wrapper"
                  >
                    <p class="invoice-date-title">
                      Due Date:
                    </p>
                    <p class="invoice-date">
                      {{ formData.dueDate }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">
            <b-card-body
              v-if="formData.invoice_status === 'paid' && payment.paidWith === 'btc'"
              class="invoice-padding pt-0"
            >
              <div>
                <center><h5><u>THIS IS JUST A PAYMENT NOTIFICATION - NOT AN INVOICE</u></h5></center>
              </div>
            </b-card-body>

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
            <div v-if="isValidInvoice === 6 ">
                  <span>
                    <h4
                    style="background: -webkit-linear-gradient(red, blue); background-clip: text; -webkit-background-clip: text;
                    -webkit-text-fill-color: text-transform; font-size: 1.1rem;"
                    >This is not a valid invoice  !  </h4>
                  </span>
                </div>
              <div class="invoice-spacing d-flex justify-content-between">
                <!-- Col: Invoice From -->
                <div
                  class="p-0"
                >
                  <h6 class="mb-2">
                    From:
                  </h6>
                  <h6 class="mb-25 font-weight-bolder">
                    {{ formData.systemSettings.company_name }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ formData.systemSettings.company_address1 }}
                  </p>
                  <!-- <p class="card-text mb-25">
                    {{ formData.systemSettings.company_address2 }}
                  </p> -->
                  <p class="card-text mb-25">
                    {{ formData.systemSettings.vatno }}
                  </p>
                </div>



                <!-- Col: To -->
                <div
                  v-if="isAnulat===0"
                  class="p-0"
                >
                  <h6 class="mb-2">
                    To:
                  </h6>
                  <h6 class="mb-25 font-weight-bolder">
                    {{ formData.client }}
                  </h6>
                  <p class="card-text mb-25 invoice-to-address">
                    {{ formData.address + ', ' + formData.country }}
                  </p>
                  <p class="card-text mb-25">
                    Tax-ID/VAT: {{ formData.tax }}
                  </p>
                </div>
              </div>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-table-lite
              responsive
              :items="formData.invoiceData.items"
              :fields="['itemTitle', 'qty', 'unitPrice', 'itemPrice']"
            >

              <template #head(itemTitle)>
                <span>Item</span>
              </template>

              <template #head(unitPrice)>
                <span>Unit Price</span>
              </template>

              <template #head(itemPrice)>
                <span>Item Price</span>
              </template>

              <template #cell(itemTitle)="data">
                <b-card-text class="font-weight-bold">
                  {{ data.value.text ? data.value.text : data.value }}
                  <br><small>{{ smallTextInfo(data.value) }}</small>
                </b-card-text>
              </template>

            </b-table-lite>

            <p
              v-if="formData.periodDate.startDate"
              class="w-100 invoice-period"
            >
              Period: {{ formData.periodDate.startDate + ' - ' + formData.periodDate.endDate }}
            </p>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <div
                v-if="isValidInvoice===6"
                class="col-12 d-flex justify-content-center pb-2 font-weight-bolder "
                style="font-size: 100%; background: -webkit-linear-gradient(red, blue);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;"
                >
                <span>{{("Amount deducted from balance").toUpperCase()}}</span>
                </div>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >

                <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        {{ formData.currency + ' ' + parseFloat(formData.subTotal).toFixed(2) }}
                      </p>
                    </div>
                    <div
                      v-if="formData.discountValue > 0"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Discount:
                      </p>
                      <p class="invoice-total-amount">
                        {{ formData.currency + ' ' + parseFloat(formData.discountValue).toFixed(2) }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        VAT ({{ formData.vatPercentage }}%):
                      </p>
                      <p class="invoice-total-amount">
                        {{ formData.currency + ' ' + parseFloat(formData.vat).toFixed(2) }}
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        {{ formData.currency + ' ' + parseFloat(formData.totalToPay).toFixed(2) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <span v-if="invoiceType === 'invoice'">
              <p
                v-if="invoiceDetails.version === 2 && invoiceDetails.currency !== 'RON'"
                class="text-right mt-1 mr-2 pr-1 mb-0"
              >
                Total in Lei (exchange rate BNR: {{ invoiceDetails.exchange_rate }} ): {{ parseFloat(invoiceDetails.exchange_rate * invoiceDetails.invoice_value).toFixed(2) }} RON
              </p>
            </span>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body
              v-if="formData.vatPercentage === 0 && isCreditNote === 0"
              class="invoice-padding text-center pt-0"
            >
              <p v-if="invoiceDetails.version === 1">
                Exempt by virtue of Art 7 of the Twelfth Schedule to the Value Added Tax Act - Chapter 406
              </p>
              <p v-else>
                Customer to account for any VAT arising on this supply in accordance with Article 196 Council Directive 2016/112/EC
              </p>
              <p
                v-if="payment"
                class="paid-style mt-1"
              >
                <span v-if="payment.paidWith === 'creditCard'">
                  Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.payment + 'I' + payment.invoice }}
                </span>
                <span v-else-if="payment.paidWith === 'credit_card'">
                  Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref ? payment.ipc_trnref : invoiceDetails.invoice_number_v2 }}
                </span>
                <span v-else-if="payment.paidWith === 'payPal'">
                  Paid by PayPal on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref }}
                </span>
                <span v-else-if="payment.paidWith === 'wire'">
                  Paid by Wire Transfer on {{ payment.payment_date }}
                </span>
              </p>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card>
            <div v-if="formData.invoiceStatus === 'paid'">THIS INVOICE IS PAID. Thank you.</div>
            <div v-if="formData.invoiceStatus==='pending' || formData.invoiceStatus==='overdue'">
              <div>
                <div v-if="setting.auto_payment_mode === 1">
                  <p
                    style="text-align:justify; text-indent: 25px;"
                  >
                    You enabled automatic payments <b>{{ autoPaymentText }}</b>, and the invoice will be charged automatically on the due date.
                  </p>
                  <p style="text-align:justify; text-indent: 25px;">
                    Thank you.
                  </p>
                  <p style="text-align:justify; text-indent: 25px;">
                    To disable this feature, go to your account setting and look for recurring payment option. <router-link to="/account">CLICK HERE</router-link>
                  </p>
                </div>
                <div v-else-if="setting.auto_payment_mode === 2">

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.sidebar-send-invoice
                    variant="primary"
                    class="mb-75"
                    block
                    :disabled="withCreditCardFlagDisabled"
                    @click="payWithCCNow()"
                  >
                    <span v-if="withCreditCardFlagDisabled">
                      <b-spinner
                        small
                        type="grow"
                      />
                      Paying with credit card...
                    </span>
                    <span v-else>Pay now using <br>{{ cardInfo.card_type }} ***** {{ cardInfo.mcard }}</span>
                  </b-button>

                  <p
                    style="text-align:justify; text-indent: 25px;"
                  >
                    You enabled automatic payments <b>{{ autoPaymentText }}</b>.
                  </p>
                  <p style="text-align:justify; text-indent: 25px;">
                    This invoice will be charged automatically on the Due Date.
                  </p>
                  <p style="text-align:justify; text-indent: 25px;">
                    If you wish to pay the invoice now, please click on the Pay Now Button above.
                  </p>
                  <p style="text-align:justify; text-indent: 25px;">
                    To change the default billing card, go to your account settings
                    <router-link to="/account">(CLICK HERE)</router-link>. To disable the auto-pay feature,
                    click on the "Disable recurring payments" button from the same page.
                  </p>
                </div>
                <div v-else>
                  <p v-if="this.formData.invoiceStatus==='overdue'">
                    Payment is <b class="note">OVERDUE</b>
                  </p>
                  <p v-else-if="this.setting.auto_payment_mode == 0 || invoice.type === type_opt_feature">
                    Payment is <b class="note">PENDING</b>
                  </p>
                </div>
                <p v-if="setting.auto_payment_mode === 0">
                  <b-form-checkbox
                    v-model="selected"
                    :value="subscribe"
                    @change="toggleEnableButtons"
                  >
                    I have read and accepted the
                    <a
                      href="#"
                      @click="showPrivacy"
                    >Privacy</a>,
                    <a
                      href="#"
                      @click="showTerms"
                    >Terms of Use</a>, and
                    <a
                      href="#"
                      @click="showBillingPolicy"
                    >Billing & Refund Policy</a>.
                  </b-form-checkbox>
                </p>
              </div>
              <div v-if="this.hasCreditCard === true && setting.auto_payment_mode === 0">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.sidebar-send-invoice
                    variant="primary"
                    class="mb-75"
                    block
                    @click="payWithCCNow"
                    :disabled="disabledButton"
                  >
                  <span v-if="this.ccLoading">
                      <b-spinner
                        small
                        type="grow"
                      />
                      Paying with credit card...
                    </span>
                    <span v-else>
                      Pay Now-{{ cardInfo.card_type }} ***** {{ cardInfo.mcard }}
                    </span>
                  </b-button>
                </div>
              <div v-if="setting.auto_payment_mode === 0">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-toggle.sidebar-send-invoice
                  variant="primary"
                  class="mb-75"
                  block
                  :disabled="disabledButton"
                  @click="payWithCard()"
                >
                  <span v-if="payingCard">
                    <b-spinner
                      small
                      type="grow"
                    />
                    Paying with Visa/Mastercard
                  </span>
                  <span v-else-if="this.hasCreditCard === true && setting.auto_payment_mode === 0">Pay with Different Visa/Mastercard</span>
                  <span v-else>Pay with Visa/Mastercard</span>
                </b-button>

                <!-- <b-button
                  v-if="isOldMaltaUser===false && invoice.version===2"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-toggle.sidebar-send-invoice
                  variant="primary"
                  class="mb-75"
                  block
                  :disabled="disabledButton"
                  @click="payWithCardSingle()"
                >
                  <span v-if="payingCardSingle">
                    <b-spinner
                      small
                      type="grow"
                    />
                    Paying with Credit Card
                  </span>
                  <span v-else>Pay with Credit Card (single)</span>
                </b-button> -->

                <!-- enabling payment recurring feature is located at the account setting -->
                <!-- <b-button
                  v-if="invoice.currency != 'RON' && user_status == 5 && !hasDiscount && setting.auto_payment_mode == 0 && optional_features.length == 0 && hasAtLeastOneInvoice"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-toggle.sidebar-send-invoice
                  variant="primary"
                  class="mb-75"
                  block
                  :disabled="disabledButton"
                  onclick="alert('todo...')"
                >
                  Pay with PayPal (recurring)
                </b-button> -->

                <b-button
                  v-b-modal.Wire-Transfer-modal
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-toggle.sidebar-send-invoice
                  variant="primary"
                  class="mb-75"
                  block
                  :disabled="disabledButton"
                >
                  Pay with Wire Transfer
                </b-button>

                <b-button v-if="invoice.currency === 'EUR'"
                  v-b-modal.preprocess-stripe-payments
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-75"
                  block
                  :disabled="disabledButton"
                >
                  Pay with AMEX & Others (Stripe)
                </b-button>

                <b-button v-if="invoice.currency === 'EUR'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-75"
                  block
                  @click="preprocessALT5Payment"
                  :disabled="disabledButton"
                >
                  Pay with Crypto
                </b-button>

                <b-button
                  v-if="invoice.currency != 'RON'"
                  ref="paypalbtn"
                  v-b-modal.preprocess-paypal-payments
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-toggle.sidebar-send-invoice
                  variant="primary"
                  class="mb-75"
                  block
                  :disabled="disabledButton"
                >
                  <span v-if="payingPaypal">
                    <b-spinner
                      small
                      type="grow"
                    />
                    Paying with Paypal
                  </span>
                  <span v-else>Pay with PayPal</span>
                </b-button>

                <div
                  alt5="Paywidget"
                  alt5_id="be180908-946c-4392-89b3-56be054f741b"
                  currency="EUR"
                  :ref_id="invoice.credit_note"
                  id="alt5_payment"
                  style="display: none;"
                >
                </div>
                <script type="application/javascript" src="https://alt5pay.com/widget/alt5pay.js"></script>

              </div>

              <div v-if="showButton === true">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.sidebar-send-invoice
                    variant="primary"
                    class="mb-75"
                    block
                    @click="balancePayment"
                    :disabled="disabledButton"
                  >
                    Pay with Balance
                  </b-button>
                </div>
            </div>
            <div v-if="formData.invoiceStatus !== 'paid'">
              <p class="note" style="font-size: 1rem; line-height: unset;">
                <b>Important: </b>PayPal's recurring payment option is currently unavailable. Please use the Credit Card option for recurring payments.
              </p>
            </div>

          </b-card>
          <div v-if="formData.invoiceStatus !== 'paid'">

<b-button
      class="btn btn-success"
      variant="outline-primary"
      block
      @click="download(invoiceId)"
    >
      <span v-if="downloading">
        <b-spinner
          small
          type="grow"
        />
        Downloading...
      </span>
      <span v-else>Download</span>
    </b-button>
  </div>
  <div v-else-if="isValidInvoice === 6">
              This is not a real invoice!
              <br>
              <b-button
                class="btn btn-success"
                variant="outline-secondary"
                block
                @click="downloadPDF"
              >
                <span v-if="downloading">
                  <b-spinner
                    small
                    type="grow"
                  />
                  Downloading...
                </span>
                <span v-else>Download</span>
              </b-button>
            </div>
            <div v-if="isValidInvoice !== 6 && formData.invoiceStatus == 'paid'">
              <br>
              <b-button
              class="btn btn-success"
                variant="outline-secondary"
                block
                @click="download(invoiceId)"
              >
                <span v-if="downloading">
                  <b-spinner
                    small
                    type="grow"
                  />
                  Downloading...
                </span>
                <span v-else>Download</span>
              </b-button>
            </div>
            <div style="display:flex; flex-direction:column; margin-top: 10px;gap:5px; justify-content: center; align-items: center;">
              <button class="btn  btn-primary btn-block"
              :disabled="!access_email"
              @click="emailInvoiceTo(invoiceId,access_email)">
                Email Invoice to
              </button>
              <b-form-group
                    class="btn-block"
                    label-for="access_email"
                    label-cols-sm="0"
                  >
                    <b-form-input
                      style="text-align: center;"
                      id="access_email"
                      v-model="access_email"
                      placeholder="(Optional)"
                    />
                  </b-form-group>
              </div>
        </b-col>

      </b-row>

      <!-- Wire Transfer Modal  -->
      <b-modal
        id="Wire-Transfer-modal"
        cancel-variant="outline-secondary"
        hide-footer
        size="lg"
        title="Wire Transfer - Payment"
      >
        <div
          v-if="!wireTransfer"
          class="text-center"
        >
          <table class="customTable">
            <tr><td class="leftcell">
              Date
            </td><td>{{ currentDate }}</td></tr>
            <tr><td>Status</td><td>Pending</td></tr>
          </table>
          <p class="note">
            Send a wire transfer to the following account:
          </p>
          <table class="customTable">
            <tr><td class="leftcell">
              IBAN
            </td><td>
              <span class="iban_code">{{ bankingProfile.iban_code }}</span>
              <b-button
                type="button"
                style="float:right"
                size="sm"
                @click="copyIban"
              >Copy</b-button>
            </td></tr>
            <tr><td class="leftcell">
              SWIFT
            </td><td>
              <span class="swift_code">{{ bankingProfile.bic_swift }}</span>
              <b-button
                type="button"
                style="float:right"
                size="sm"
                @click="copySwift"
              >Copy</b-button>
            </td></tr>
            <tr><td class="leftcell">
              Account holder name
            </td><td>
              <span class="account_holder_name">{{ bankingProfile.company_name }}</span>
              <b-button
                type="button"
                style="float:right"
                size="sm"
                @click="copyAccountHolderName"
              >Copy</b-button>
            </td></tr>
            <tr><td class="leftcell">
              Bank name
            </td><td>
              <span class="bank_name">{{ bankingProfile.bank_name }}</span>
              <b-button
                type="button"
                style="float:right"
                size="sm"
                @click="copyBankName"
              >Copy</b-button>
            </td></tr>
            <tr><td class="leftcell">
              Amount
            </td><td>
              <span class="cpy_amount">{{ invoice.currency }} {{ parseFloat(invoice.invoice_value).toFixed(2) }}</span>
              <b-button
                type="button"
                style="float:right"
                size="sm"
                @click="copyWireAmount"
              >Copy</b-button>
            </td></tr>
          </table>

          <b-button
            variant="success"
            block
            style="margin-top:8px; width: 200px;float: right;"
            :disabled="sendingWire"
            @click="payWithWireTransfer"
          >
            <span v-if="sendingWire">
              <b-spinner
                small
                type="grow"
              />
              Saving...
            </span>
            <span v-else> I've sent the funds</span>
          </b-button>
        </div>
        <div
          v-else
          class="text-center"
          style="padding:30px"
        >
          <h5>You have a pending wire transfer on this invoice.</h5>
        </div>
      </b-modal>

      <!-- Btc Transfer Modal  -->
      <b-modal
        id="btc-Transfer-modal"
        cancel-variant="outline-secondary"
        hide-footer
        size="lg"
        title="Pay with Crypto"
        ize="lg"
      >
        <div
          v-if="!btcTransfer"
          class="text-center btc-modal"
        >
          <table class="customTable">
            <tr><td class="leftcell">
              Date
            </td><td>{{ currentDate }}</td></tr>
            <tr><td>Status</td><td>Pending</td></tr>
          </table>
          <p class="note">
            Send a BTC transfer to the following wallet:
          </p>
          <table class="customTable">
            <tr><td class="leftcell">
              BTC Address
            </td><td>
              <span
                class="btc_address"
              >
                {{ btcProfile.btc_address }}
              </span>
              <b-button
                type="button"
                style="float:right"
                size="sm"
                @click="copyBtcAddress"
              >Copy
              </b-button>
            </td></tr>
            <tr><td class="leftcell">
              Amount
            </td><td>
              <span
                class="amount"
              >€ {{ parseFloat(invoice.valueInEuroNoVat).toFixed(2) }}</span>
              <b-button
                type="button"
                style="float:right"
                size="sm"
                @click="copyAmount"
              >Copy</b-button>
            </td></tr>
            <tr><td class="leftcell">
              Amount in BTC
            </td><td>
              <span
                class="amountInBtc"
              >{{ amountInBTC(invoice.valueInEuroNoVat,btcProfile.btcRate) }}</span>
              <b-button
                type="button"
                style="float:right"
                size="sm"
                @click="copyAmountInBtc"
              >Copy</b-button></td></tr>
            <tr><td class="leftcell">
              Live Rate BTC-EUR
            </td><td>{{ btcProfile.btcRate }} <a
              class="btn btn-info sm"
              target="_blank"
              href="https://www.coinbase.com/price/bitcoin"
              style="float:right"
            >source: Coinbase.com</a></td></tr>
          </table>
          <b-button
            variant="success"
            block
            style="margin-top:8px; width: 200px;float: right;"
            :disabled="sendingBtc"
            @click="payWithCrypto"
          >
            <span v-if="sendingBtc">
              <b-spinner
                small
                type="grow"
              />
              Saving...
            </span>
            <span v-else> I've sent the funds</span>
          </b-button>
        </div>

        <div
          v-else
          class="text-center"
          style="padding:30px"
        >
          <h5>You have a pending BTC transfer on this invoice.</h5>
        </div>

      </b-modal>
      <b-modal
        id="terms-modal"
        scrollable
        title="Terms of Use"
      >
        <terms-of-use />
      </b-modal>
      <b-modal
        id="privacy-modal"
        scrollable
        title="Privacy Policy"
      >
        <privacy />
      </b-modal>
      <b-modal
        id="billing-policy-modal"
        scrollable
        title="Billing & Refund Policy"
      >
        <billing-policy />
      </b-modal>
      <b-modal
        id="send-alt5-payments"
        title="Crypto Payment"
        no-close-on-backdrop
        hide-header-close
        no-close-on-esc
        ok-only
        @ok="cryptoALT5PaymentSend"
      >
        <h5 class="px-2 text-center" style="margin-top: 20px;">We are waiting for your payment to be confirmed by the network.<br>Please come back later. Thank you.</h5>
      </b-modal>
      <b-modal
        id="preprocess-alt5-payments"
        title="Crypto Payment"
        no-close-on-backdrop
        hide-header-close
        ok-title="Continue"
        @ok="cryptoALT5Payment"
      >
        <h5 class="px-2 text-center" style="margin-top: 20px;">
          Please be aware that a processing fee of <strong>5%</strong> will be applied to payments made with <strong>Crypto</strong>.<br><br>
          To avoid processing fees, please pay using the <br><strong>"Pay with Visa/Mastercard"</strong> option.
        </h5>
      </b-modal>
      <b-modal
        id="preprocess-stripe-payments"
        title="AMEX (Stripe) Payment"
        no-close-on-backdrop
        hide-header-close
        ok-title="Continue"
        @ok="processStripePayments"
      >
        <h5 class="px-2 text-center" style="margin-top: 20px;">
          Please be aware that a processing fee of <strong>2%</strong> will be applied to payments made with <strong>AMEX (Stripe)</strong>.<br><br>
          To avoid processing fees, please pay using the <br><strong>"Pay with Visa/Mastercard"</strong> option.
        </h5>
      </b-modal>
      <b-modal
        id="preprocess-stripe-payments-now"
        title="AMEX (Stripe) Payment"
        no-close-on-backdrop
        hide-header-close
        ok-title="Continue"
        @ok="processStripePaymentsNow"
      >
        <h5 class="px-2 text-center" style="margin-top: 20px;">
          Please be aware that a processing fee of <strong>2%</strong> will be applied to payments made with <strong>AMEX (Stripe)</strong>.<br><br>
          To avoid processing fees, please pay using the <br><strong>"Pay with Visa/Mastercard"</strong> option.
        </h5>
      </b-modal>
      <b-modal
        id="preprocess-paypal-payments"
        title="Paypal Payment"
        no-close-on-backdrop
        hide-header-close
        ok-title="Continue"
        @ok="paypalPayment"
      >
        <h5 class="px-2 text-center" style="margin-top: 20px;">
          Please be aware that a processing fee of <strong>3%</strong> will be applied to payments made with <strong>Paypal</strong>.<br><br>
          To avoid processing fees, please pay using the <br><strong>"Pay with Visa/Mastercard"</strong> option.
        </h5>
      </b-modal>
    </section>
  </div>
  </span>
  <!-- !!!!!!!!!!!!!!!!From down here is the PDF that is downloadable!!!!!!!!!!!!!!!!! -->
  <div style="width:100%">
    <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        :filename="pdfFilename"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="auto"
        ref="html2Pdf"
    >
  <section slot="pdf-content">
    <div style="margin: 0 auto; padding-left: 18%; padding-top: 5%;">
    <span>
    <div ref='content'>
    <b-row
      v-if="!invoiceId"
      class="invoice-preview"
    >
      <b-col
        cols="20"
      >
        <b-card>
          <h6>No current invoice available.</h6>
        </b-card>
      </b-col>
    </b-row>
    <section
      v-if="formData.systemSettings"
      class="invoice-preview-wrapper"
    >
      <b-row
        class="invoice-preview"
      >
        <b-col
          v-if="invoiceMessage"
          cols="12"
        >
          <b-alert
            show
            variant="secondary"
            dismissible
          >
            <h4 class="alert-heading">
              <feather-icon
                icon="InfoIcon"
                size="18"
              /> Notice
            </h4>
            <p style="padding:10px 10px 0px 50px">
              {{ invoiceMessage }}
            </p>
            <hr>
          </b-alert>
        </b-col>

        <!-- Col: Left (Invoice Container) -->
        <b-col
          id="printMe"
          ref="printMe"
          cols="25"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body
              class="invoice-padding pb-0"
            >

              <div class="d-flex justify-content-between flex-md-row flex-column mt-0">

                <!-- Header: Left Content -->
                <div class="d-flex">
                  <div class="logo-wrapper">
                    <b-img
                      :src="require('@/assets/images/logo/tm-partial-logo-2.png')"
                      alt="logo"
                    />
                    <h3 class="text-primary invoice-logo ml-0">
                      {{ appName }}
                    </h3>
                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    {{ formData.invoiceNo }}
                  </h4>

                  <div class="invoice-date-wrapper mb-0">
                    <p class="invoice-date-title">
                      Date Issued:
                    </p>
                    <p class="invoice-date">
                      {{ formData.dateIssued }}
                    </p>
                  </div>

                  <div
                    v-if="formData.dueDate"
                    class="invoice-date-wrapper"
                  >
                    <p class="invoice-date-title">
                      Due Date:
                    </p>
                    <p class="invoice-date">
                      {{ formData.dueDate }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">
            <b-card-body
              v-if="formData.invoice_status === 'paid' && payment.paidWith === 'btc'"
              class="invoice-padding pt-0"
            >
              <div>
                <center><h5><u>THIS IS JUST A PAYMENT NOTIFICATION - NOT AN INVOICE</u></h5></center>
              </div>
            </b-card-body>

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
            <div v-if="isValidInvoice === 6 ">
                  <span>
                    <h4
                    style="color:red;text-transform: uppercase; font-size: 1.1rem;"
                    >This is not a valid invoice  !  </h4>
                  </span>
                </div>
              <div class="invoice-spacing d-flex justify-content-between">
                <!-- Col: Invoice From -->
                <div
                  class="p-0"
                >
                  <h6 class="mb-2">
                    From:
                  </h6>
                  <h6 class="mb-25 font-weight-bolder">
                    {{ formData.systemSettings.company_name }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ formData.systemSettings.company_address1 }}
                  </p>
                  <!-- <p class="card-text mb-25">
                    {{ formData.systemSettings.company_address2 }}
                  </p> -->
                  <p class="card-text mb-25">
                    {{ formData.systemSettings.vatno }}
                  </p>
                </div>



                <!-- Col: To -->
                <div
                  v-if="isAnulat===0"
                  class="p-0"
                >
                  <h6 class="mb-2">
                    To:
                  </h6>
                  <h6 class="mb-25 font-weight-bolder">
                    {{ formData.client }}
                  </h6>
                  <p class="card-text mb-25 invoice-to-address">
                    {{ formData.address + ', ' + formData.country }}
                  </p>
                  <p class="card-text mb-25">
                    Tax-ID/VAT: {{ formData.tax }}
                  </p>
                </div>
              </div>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-table-lite
              responsive
              :items="formData.invoiceData.items"
              :fields="['itemTitle', 'qty', 'unitPrice', 'itemPrice']"
            >

              <template #head(itemTitle)>
                <span>Item</span>
              </template>

              <template #head(unitPrice)>
                <span>Unit Price</span>
              </template>

              <template #head(itemPrice)>
                <span>Item Price</span>
              </template>

              <template #cell(itemTitle)="data">
                <b-card-text class="font-weight-bold">
                  {{ data.value.text ? data.value.text : data.value }}
                </b-card-text>
              </template>

            </b-table-lite>

            <p
              v-if="formData.periodDate.startDate"
              class="w-100 invoice-period"
            >
              Period: {{ formData.periodDate.startDate + ' - ' + formData.periodDate.endDate }}
            </p>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <div
                v-if="isValidInvoice===6"
                class="col-12 d-flex justify-content-center pb-2 font-weight-bolder "
                style="font-size: 100%; color: red; text-transform: uppercase;"
                >
                <span>{{("Amount deducted from balance").toUpperCase()}}</span>
                </div>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >

                <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        {{ formData.currency + ' ' + parseFloat(formData.subTotal).toFixed(2) }}
                      </p>
                    </div>
                    <div
                      v-if="formData.discountValue > 0"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Discount:
                      </p>
                      <p class="invoice-total-amount">
                        {{ formData.currency + ' ' + parseFloat(formData.discountValue).toFixed(2) }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        VAT ({{ formData.vatPercentage }}%):
                      </p>
                      <p class="invoice-total-amount">
                        {{ formData.currency + ' ' + parseFloat(formData.vat).toFixed(2) }}
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        {{ formData.currency + ' ' + parseFloat(formData.totalToPay).toFixed(2) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <span v-if="invoiceType === 'invoice'">
              <p
                v-if="invoiceDetails.version === 2 && invoiceDetails.currency !== 'RON'"
                class="text-right mt-1 mr-2 pr-1 mb-0"
              >
                Total in Lei (exchange rate BNR: {{ invoiceDetails.exchange_rate }} ): {{ parseFloat(invoiceDetails.exchange_rate * invoiceDetails.invoice_value).toFixed(2) }} RON
              </p>
            </span>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body
              v-if="formData.vatPercentage === 0 && isCreditNote === 0"
              class="invoice-padding text-center pt-0"
            >
              <p v-if="invoiceDetails.version === 1">
                Exempt by virtue of Art 7 of the Twelfth Schedule to the Value Added Tax Act - Chapter 406
              </p>
              <p v-else>
                Customer to account for any VAT arising on this supply in accordance with Article 196 Council Directive 2016/112/EC
              </p>
              <p
                v-if="payment"
                class="paid-style mt-1"
              >
                <span v-if="payment.paidWith === 'creditCard'">
                  Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.payment + 'I' + payment.invoice }}
                </span>
                <span v-else-if="payment.paidWith === 'credit_card'">
                  Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref ? payment.ipc_trnref : invoiceDetails.invoice_number_v2 }}
                </span>
                <span v-else-if="payment.paidWith === 'payPal'">
                  Paid by PayPal on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref }}
                </span>
                <span v-else-if="payment.paidWith === 'wire'">
                  Paid by Wire Transfer on {{ payment.payment_date }}
                </span>
              </p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
  </span>
</div>
</section>
</VueHtml2pdf>
</div>
</div>
</template>
<script>
/* eslint-disable */
import VueHtml2pdf from 'vue-html2pdf'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BButton, BImg, BCardText,
  VBToggle, BModal, BFormCheckbox, BSpinner, BAlert, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import Vue from 'vue'
import VueHtml2Canvas from 'vue-html2canvas'
import axios from '@/libs/axios'
import moment from 'moment'
import Terms from './Terms.vue'
import Privacy from './Privacy.vue'
import BillingPolicy from './BillingPolicy.vue'

Vue.use(VueHtml2Canvas)

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BButton,
    BImg,
    BCardText,
    BModal,
    BFormCheckbox,
    BSpinner,
    BAlert,
    BFormGroup,
    BFormInput,
    'terms-of-use': Terms,
    privacy: Privacy,
    'billing-policy': BillingPolicy,
    VueHtml2pdf,
  },
  data() {
    return {
      withCreditCardFlagDisabled: true,
      pdfFilename: '',
      downloading: false,
      autoPaymentText: '',
      invoiceMessage: '',
      invoiceId: 0,
      subscribe: 0,
      selected: [],
      disabledButton: true,
      status: 'Paid',
      invoiceType: '',
      isValidInvoice: '',
      invoiceDetails: {},
      payment: null,
      items: {},
      appName: null,
      currentDate: moment(new Date()).format('DD/MM/YYYY'),
      sendingWire: false,
      sendingBtc: false,
      wireTransfer: null,
      btcTransfer: null,
      isOldMaltaUser: false,
      hasDiscount: false,
      user_status: 0,
      optional_features: [],
      showButton: false,
      formData: {
        dateIssued: '',
        dueDate: '',
        invoiceTo: null,
        client: '',
        address: '',
        tax: '',
        country: '',
        subTotal: 0,
        totalToPay: 0,
        vat: 0,
        vatPercentage: 0,
        bankDetails: '',
        existingClients: false,
        systemSettings: null,
        invoiceNo: 0,
        currency: '',
        discountValue: 0,
        periodDate: {
          startDate: null,
          endDate: null,
        },
        invoiceData: {
          items: [],
        },
        invoiceStatus: '',
      },

      setting: {
        auto_payment_mode: 0,
        type: '',
      },

      invoice: {
        credit_note: 0,
        invoice_value: 0,
        currency: 0,
        valueInEuroNoVat: 0,
        type: '',
        invoice: 0,
      },

      bankingProfile: {
        id: 0,
        iban_code: '',
        bic_swift: '',
        company_name: '',
        bank_name: '',
      },

      btcProfile: {
        id: 0,
        btc_address: '',
        btcRate: 0,
      },
      type_opt_feature: 0,
      hasAtLeastOneInvoice: 0,
      old_malta_users: [],
      paypalAction: `${process.env.VUE_APP_PORTAL}login`,
      payingPaypal: false,
      payingCardSingle: false,
      payingCard: false,
      isAnulat: 0,
      isCreditNote: 0,
      ccLoading: false,
      hasCreditCard: false,
      cardInfo: [],
      access_email: '',
      scheduleSession: false,
    }
  },
  created() {
    this.getCurrentInvoice()
    this.appName = $themeConfig.app.appName
    this.invoiceMessage = localStorage.getItem('invoice_message')
    this.scheduleSession = localStorage.getItem('redirectToSession')
    this.checkHasCard()
    this.getAccessEmail()
    this.hideBalanceButton()
  },
  mounted() {
    document.body.onclick = (e) => {
      if (e.target.classList.contains('btnalt5pay')) this.$bvModal.show('send-alt5-payments');
    }
    this.checkUrlForError()
  },
  computed : {
    userData(){
      return this.$store.state.auth.userData
    }
  },
  methods: {
    checkUrlForError() {
      const urlParams = new URLSearchParams(window.location.search);
      const error = urlParams.get('error');
      if (error) {
        this.notify({
          text: error,
          variant: 'danger',
        })
      window.history.pushState({}, document.title, window.location.pathname);
      }
    },
    smallTextInfo(value) {
      if (value?.text == "Late Payment Penalty Compensation" || value == "Late Payment Penalty Compensation") return "in line with the European Late Payment Directive (2011/7/EU)";
    },

    processStripePayments() {
      window.location.href = `${process.env.VUE_APP_PORTAL}stripe/invoice/${this.invoice.credit_note}`;
    },
    processStripePaymentsNow() {
      return;
      window.location.href = `${process.env.VUE_APP_PORTAL}stripe/invoice-pay-now/${this.invoice.credit_note}`;
    },

    cryptoALT5PaymentSend() {
      this.$router.push('/home');
    },

    preprocessALT5Payment() {
      const button = document.getElementById('alt5_payment');
      const amount = (parseFloat(this.invoice.invoice_value) * 0.05 + parseFloat(this.invoice.invoice_value));
      button.setAttribute('amount', amount);
      this.$bvModal.show('preprocess-alt5-payments');
    },

    cryptoALT5Payment() {
      const button = document.getElementById('alt5_payment');
      button.click()
    },

    hideBalanceButton() {
      axios
        .get('/api/account/get_user_balance')
        .then(res => {
          if (res.data.balance > 0) {
            this.showButton = true
          } else {
            this.showButton = false
          }
        })
    },
    async emailInvoiceTo(invoice, email) {
      axios
        .post('/api/account/send_invoice_now', {
          creditNote: invoice,
          access_email: email,
        })
        .then(res => {
          if (res?.status === 200) {
            this.notify({
              text: `Invoice has been successfully sent to  ${this.access_email}!`,
              variant: 'success',
            })
          } else {
            this.notify({
              text: 'Invoice has not been sent! Please try again or contact support!',
              variant: 'danger',
            })
          }
        })
    },
    async getAccessEmail() {
      const response = await axios.get('/api/account/get_access_email')
      if (response.status === 200) {
        this.access_email = response.data.access_email
      }
    },
    balancePayment() {
      this.confirmationAlert('Would you like to pay this invoice with your balance?')
        .then(async res => {
          if (res.isConfirmed) {
            const response = await axios.post('/api/wire_transfer/affiliate_balance/create_payments', { creditNote: this.invoice.credit_note })
            if (response?.status !== 200 && !response) {
              this.notify({
                text: 'Payment has not been submitted. Insufficient balance!',
                variant: 'danger',
              })
              return
            }

            if (response.data.status) {
              this.notify({
                text: 'Payment has been successfully submitted. Thank you.',
                variant: 'success',
              })
              if(this.scheduleSession) {
                this.$router.push('/optional-features');
                this.scheduleSession = false;
              }
              else window.location.reload()
            } else {
              this.notify({
                text: response.data.msg,
                variant: 'danger',
              })
            }
          }
        })
    },

    checkHasCard() {
      axios.get('/api/euplatesc/has_card').then(res => {
        if (typeof res.data === 'object' && res.data.id) {
          this.hasCreditCard = true
          this.cardInfo = res.data
        }
      })
    },
    async payWithWireTransfer() {
      if (this.sendingWire) return
      this.sendingWire = true
      const payload = {
        creditNote: this.invoice.credit_note,
        bankingProfile: this.bankingProfile.id,
      }
      const response = await axios.post('/api/wire_transfer/create_payments', payload)
      if (response.status === 200) {
        this.notify({
          text: 'Payment has been successfully submitted. We will verify the wire transfer at the soonest possible time. Thank you.',
          variant: 'success',
        })

        if (this.$store.state.auth.impersonate === true) {
          this.$swal({
            text: 'Do you want to redirect to the list of wire transfers?',
            icon: 'question',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary mr-1',
              cancelButton: 'btn btn-secondary',
            },
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            buttonsStyling: false,
          }).then(res => {
            if (res.isConfirmed) {
              this.$store.dispatch('auth/exitImpersonation').then(() => {
                window.location = '/billing/wire-transfers'
              })
            }else{
              this.$router.push('/home')
            }
          })
        } else this.$router.push('/home')
      } else {
        this.notify({
          text: 'Payment has not been submitted. We will verify the error at the earliest possible time. Thank you.',
          variant: 'danger',
        })
      }
      this.sendingWire = false
    },

    async payWithCrypto() {
      this.sendingBtc = true
      const payload = {
        creditNote: this.invoice.credit_note,
        btcProfile: this.btcProfile.id,
      }
      const response = await axios.post('/api/btc_transfer/create_payments', payload)
      if (response.status === 200) {
        this.notify({
          text: 'Payment has been successfully submitted. We will verify the wire transfer at the soonest possible time. Thank you.',
          variant: 'success',
        })
        this.$router.push('/billing/btc-transfer')
      } else {
        this.notify({
          text: 'Payment has not been submitted. We will verify the error at the earliest possible time. Thank you.',
          variant: 'danger',
        })
      }
      this.sendingBtc = false
    },

    copyBtcAddress() {
      const range = this.getRange('btc_address')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyAmount() {
      const range = this.getRange('amount')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyAmountInBtc() {
      const range = this.getRange('amountInBtc')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyIban() {
      const range = this.getRange('iban_code')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copySwift() {
      const range = this.getRange('swift_code')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyAccountHolderName() {
      const range = this.getRange('account_holder_name')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyBankName() {
      const range = this.getRange('bank_name')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyWireAmount() {
      const range = this.getRange('cpy_amount')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copySelectedText(range) {
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
      document.execCommand('copy')
    },
    getRange(classname) {
      const el = document.getElementsByClassName(classname)[0]
      const range = document.createRange()
      range.selectNodeContents(el)
      return range
    },
    notifyCopiedText(strcpd) {
      const str = strcpd.toString().trim()
      this.notify({
        text: `"${str}" is copied!`,
        variant: 'success',
      })
    },

    toggleEnableButtons() {
      if (this.selected.length) this.disabledButton = false
      else this.disabledButton = true
    },
    async getCurrentInvoice() {
      this.invoiceId = this.$route.params.id
      const user = this.$store.state.auth.userData

      const user_uid = user.uid

      if (typeof this.invoiceId === 'undefined') {
        const response = await axios.get(`/api/invoices/user/${user_uid}`)
        if (response.status === 200) {
          this.invoiceId = response.data.id
        }
      }
      if (this.invoiceId) {
        this.$store.dispatch('invoice/getInvoiceByID', this.invoiceId)
          .then(res => {
            this.isAnulat = Number(res.is_anulat)
            this.invoiceType = res.invoiceType
            this.isValidInvoice = res.invoice.type
            this.invoiceDetails = res.invoice
            this.formData.systemSettings = res.systemSettings

            const currency = res.invoice.currency === 'EUR' ? '€' : res.invoice.currency
            this.formData.invoiceStatus = res.invoice.invoice_status

            this.setting.auto_payment_mode = Number(res.setting.auto_payment_mode)
            if (this.setting.auto_payment_mode === 1) this.autoPaymentText = 'with your Paypal'
            else if (this.setting.auto_payment_mode === 2) this.autoPaymentText = 'with your Credit Card'
            if (this.setting.auto_payment_mode === 2) this.withCreditCardFlagDisabled = false

            this.invoice.credit_note = res.invoice.credit_note
            this.invoice.invoice_value = res.invoice.invoice_value
            this.invoice.currency = res.invoice.currency
            this.invoice.valueInEuroNoVat = res.valueInEuroNoVat
            this.invoice.type = res.invoice.type
            this.invoice.version = res.invoice.version

            this.bankingProfile.id = res.bankingProfile.id
            this.bankingProfile.iban_code = res.iban

            this.bankingProfile.bic_swift = res.bankingProfile.bic_swift
            this.bankingProfile.company_name = res.bankingProfile.company_name
            this.bankingProfile.bank_name = res.bankingProfile.bank_name

            this.btcProfile.id = res.btcProfile.id
            this.btcProfile.btc_address = res.btcProfile.btc_address
            this.btcProfile.btcRate = res.btcRate

            this.wireTransfer = res.wireTransfer
            this.btcTransfer = res.btcTransfer
            this.type_opt_feature = res.TYPE_OPT_FEATURE
            this.hasAtLeastOneInvoice = res.hasAtLeastOneInvoice
            this.old_malta_users = res.OLD_MALTA_USERS
            this.hasDiscount = res.hasDiscount
            this.user_status = res.user_status
            this.optional_features = res.optional_features

            if (this.old_malta_users.indexOf(user.id) >= 0) this.isOldMaltaUser = true

            if (res.invoiceType === 'invoice') {
              this.payment = res.payment

              if (!(res.invoice.invoice || res.invoice.invoice_number_v2) || res.invoice.is_credit_note === 1) this.isCreditNote = 1
              if (this.isAnulat === 1) this.isCreditNote = 0

              if (this.isCreditNote === 0 && res.invoice.paid_by_balance === 0) {
                this.formData.invoiceNo = 'Invoice #'
                if (res.invoice.version === 1) this.formData.invoiceNo += res.invoice.invoice
                else this.formData.invoiceNo += `TM${res.invoice.invoice_number_v2.toString().padStart(4, '0')}`
                this.pdfFilename = this.formData.invoiceNo
              } else {
                this.formData.invoiceNo = `Pro Forma Invoice #${res.invoice.credit_note}`
                this.pdfFilename = `Pro Forma #${res.invoice.credit_note}`
              }

              this.formData.address = res.billing.bill_address1
              // if (res.billing.bill_address2) this.formData.address += `${res.billing.bill_address1}, ${res.billing.bill_address2}`
              this.formData.address += ` ${res.billing.bill_city}, ${res.billing.bill_postcode} ${res.billing.bill_region}`

              this.formData.dateIssued = res.invoice.issue_date
              this.formData.dueDate = res.invoice.due_date
              this.formData.client = res.billing.bill_name
              this.formData.tax = res.billing.bill_vatno
              this.formData.country = res.billingCountry

              if (!this.formData.client) this.formData.client = res.invoice_to_name
              this.pdfFilename = `${this.pdfFilename} ${this.formData.client}`

              const cur = res.invoice.currency.toUpperCase()

              res.items.forEach(item => {
                let itemPrice = item.item_price
                let unitPrice = item.unit_price
                const { type } = item
                const mainItem = item.item

                if (cur === 'RON') {
                  itemPrice = item.item_price * res.invoice.exchange_rate
                  unitPrice = item.unit_price * res.invoice.exchange_rate
                }

                this.formData.invoiceData.items.push({
                  itemTitle: item.item,
                  qty: item.qty, 
                  unitPrice: `${currency} ${this.formatNumber(unitPrice, type, mainItem)}`,
                  itemPrice: `${currency} ${this.formatNumber(itemPrice, type, mainItem)}`,
                })
              })

              this.formData.periodDate.startDate = res.invoice.start_date
              this.formData.periodDate.endDate = res.invoice.end_date
              this.formData.currency = currency
              this.formData.subTotal = parseFloat(res.invoice.subtotal_value).toFixed(2)
              this.formData.totalToPay = parseFloat(res.invoice.invoice_value).toFixed(2)

              this.formData.vat = res.invoice.vat_value
              this.formData.vatPercentage = res.invoice.vat
              this.formData.discountValue = res.invoice.discount_value
            } else {
              this.formData.invoiceNo = res.invoice.version === 1 ? `Invoice# ${res.invoice.invoiceNo}` : `Invoice#  TM${res.invoice.invoice_number_v2.toString().padStart(4, '0')}`
              this.formData.dateIssued = res.invoice.issueDate
              this.formData.dueDate = res.invoice.dueDate
              this.formData.client = res.invoice.to
              this.formData.address = res.invoice.userAddress
              this.formData.country = res.billingCountry
              this.formData.tax = res.invoice.userVAT
              this.formData.subTotal = parseFloat(res.invoice.subtotal).toFixed(2)
              this.formData.totalToPay = parseFloat(res.invoice.total).toFixed(2)
              this.formData.vat = parseFloat(res.invoice.vatValue).toFixed(2)
              this.formData.vatPercentage = res.invoice.vatPercent
              this.formData.periodDate.startDate = res.invoice.periodStart
              this.formData.periodDate.endDate = res.invoice.periodEnd
              this.formData.currency = currency

              this.pdfFilename = this.formData.invoiceNo
              this.pdfFilename = `${this.pdfFilename} ${this.formData.client}`

              const cur = res.invoice.currency.toUpperCase()
              let itemPriceFirst = res.invoice.packageItemPrice
              let unitPriceFirst = res.invoice.packageUnitPrice
              if (cur === 'RON') {
                itemPriceFirst = res.invoice.packageItemPrice * res.invoice.exchange_rate
                unitPriceFirst = res.invoice.packageUnitPrice * res.invoice.exchange_rate
              }

              this.formData.invoiceData.items.push({
                itemTitle: res.invoice.packageName,
                qty: res.invoice.packageQty,
                unitPrice: `${currency} ${parseFloat(unitPriceFirst)}`,
                itemPrice: `${currency} ${parseFloat(itemPriceFirst)}`,
              })

              res.items.forEach(item => {
                let itemPrice = item.customItemPrice
                let unitPrice = item.customUnitPrice
                const { type } = item
                const mainItem = item.item

                if (cur === 'RON') {
                  itemPrice = item.customItemPrice * res.invoice.exchange_rate
                  unitPrice = item.customUnitPrice * res.invoice.exchange_rate
                }

                this.formData.invoiceData.items.push({
                  itemTitle: item.customProductName,
                  qty: item.customQty,
                  unitPrice: `${currency} ${this.formatNumber(unitPrice, type, mainItem)}`,
                  itemPrice: `${currency} ${this.formatNumber(itemPrice, type, mainItem)}`,
                })
              })
            }
          })
          .catch(e => {
            if (e.response.status === 404) this.$router.push('/error-404')
            else this.$router.push('/not-authorized')
          })
      }
    },
    formatNumber(price, type, title = null) {
      const priceFinal = Number(price)
      if ((title && title?.toLowerCase().includes('processing fee')) || type === 3) {
        return priceFinal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
      if (type === 5) {
        return priceFinal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 6,
        })
      }
      return priceFinal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
      })
    },
    getPdfCanvas() {
      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */
      el = this.$refs.printMe
      const options = { type: 'dataURL' }
      return new this.$html2canvas(el, options)
    },
    showTerms() {
      this.$bvModal.show('terms-modal')
    },
    showPrivacy() {
      this.$bvModal.show('privacy-modal')
    },
    showBillingPolicy() {
      this.$bvModal.show('billing-policy-modal')
    },
    async paypalPayment() {
      this.payingPaypal = true
      this.$refs.paypalbtn.disabledButton = true
      const payload = {
        invoice: this.invoice.credit_note,
        type: this.invoiceType,
        invoiceNo: this.formData.invoiceNo,
      }
      const res = await this.$store.dispatch('invoice/getPaypalUrl', payload)
      if (typeof res.url !== 'undefined') window.location = res.url
    },

    payWithCardSingle() {
      this.payingCardSingle = true
      const payload = {
        invoice: this.invoice.credit_note,
      }
      this.cardPayment(payload)
    },

    async payWithCCNow() {
      this.withCreditCardFlagDisabled = true
      this.ccLoading = true
      const payload = {
        credit_note: this.invoice.credit_note,
      }

      let uri = '/api/invoices/pay_invoice'
      let response = await axios.post(uri, payload)

      if (typeof response.data === 'object' && response.data.res === 1) {
        let check_status = 0
        const credit_note_param = this.invoice.credit_note
        const refreshId = setInterval(async () => {
          uri = `/api/progress/payment_receive?credit_note=${credit_note_param}`
          response = await axios.get(uri)
          if (typeof response.data === 'object' && response.data.is_paid === 1) {
            clearInterval(refreshId)
            // eslint-disable-next-line no-alert
              this.notify({
                varant: 'success',
                text: 'Payment successful. You will be redirected to the invoice page.',
                autoHideDelay: 5000,
              })
            if(this.scheduleSession) {
              this.$router.push('/optional-features');
              this.scheduleSession = false;
              return;
            }
            // wait 5 seconds
            setTimeout(() => {
              window.location.reload()
            }, 5000)
          }
          if (check_status >= 10) {
            clearInterval(refreshId)
            // eslint-disable-next-line no-alert
            this.notify({
              variant: 'danger',
              text: 'Payment failed. Please try again or contact support.',
              autoHideDelay: 5000,
            })
            // wait 5 seconds
            setTimeout(() => {
              window.location.reload()
            }, 5000)
          }
          check_status += 1
        }, 1000)
      }
    },

    payWithCard() {
      this.paywithcc()

      // just comment it.. incase Florin will ask again
      // if (this.hasCreditCard) {
      //   this.confirmationAlert('Would you like to enable recurring payment with your credit card?')
      //     .then(async res => {
      //       if (res.isConfirmed) {
      //         const payload = {}
      //         const uri = '/api/euplatesc/set_to_recurring'
      //         axios.post(uri, payload)
      //         this.paywithcc()
      //       } else {
      //         this.paywithcc()
      //       }
      //     })
      // } else {
      //   this.paywithcc()
      // }
    },

    paywithcc() {
      this.payingCard = true
      const payload = {
        invoice: this.invoice.credit_note,
        isRecurrent: true,
      }
      this.cardPayment(payload)
    },

    async cardPayment(payload) {
      const uri = '/api/euplatesc/checkout'
      const response = await axios.post(uri, payload)
      if (response.status === 200) {
        const { data } = response
        let orderDesc = ''
        if (data.order_desc) orderDesc = data.order_desc

        // formData object is just use to map the data properly,
        // or you can use regular array.
        const formData = new FormData()
        formData.append('amount', data.amount)
        formData.append('curr', data.curr)
        formData.append('invoice_id', data.invoice_id)
        formData.append('order_desc', orderDesc)
        formData.append('merch_id', data.merch_id)
        formData.append('timestamp', data.timestamp)
        formData.append('nonce', data.nonce)
        formData.append('recurent_freq', data.recurent_freq)
        formData.append('recurent_exp', data.recurent_exp)
        formData.append('recurent', data.recurent)
        formData.append('email', data.email)
        formData.append('lang', data.lang)
        formData.append('fp_hash', data.fp_hash)
        formData.append('ExtraData[silenturl]', data.silenturl)
        formData.append('ExtraData[successurl]', data.successurl)

        // create a actual form
        const ccForm = document.createElement('form')
        ccForm.method = 'post'
        ccForm.action = data.actionUrL
        ccForm.id = 'ccForm_to_submit'

        // create hidden input
        for (const [key, value] of formData) { // eslint-disable-line
          const ccInput = document.createElement('input')
          ccInput.type = 'hidden'
          ccInput.name = key
          ccInput.value = value
          ccForm.appendChild(ccInput)
        }

        // append this to the body and submit the form.
        document.body.appendChild(ccForm)
        document.getElementById('ccForm_to_submit').submit()

        setTimeout(() => {
          if(this.scheduleSession) {
            this.$router.push('/optional-features');
            this.scheduleSession = false;
          }
        }, 1000);
      }
    },
    // disable eslint

    async download(id) {
      this.downloading = true
      const url = `/api/invoices/download/${id}`
      await axios.get(url, { responseType: 'blob' })
        .then(res => {
          const link = document.createElement('a')
          const pdf_url = URL.createObjectURL(res.data)
          link.setAttribute('href', pdf_url)
          link.setAttribute('download', this.pdfFilename.endsWith('.') ? `${this.pdfFilename}pdf` : this.pdfFilename)
          link.style.visibility = 'hidden'
          link.id = 'downloadPDF'
          document.body.appendChild(link)
          document.getElementById(link.id).click()

          // remove to make sure a new csv file will be downloaded and not the previous one
          const element = document.getElementById(link.id)
          element.remove()
        })
      this.downloading = false
    },
    async download2(id) {
      this.downloading = true
      const url = `/api/invoices/download/${id}`
      await axios.get(url, { responseType: 'blob' })
        .then(res => {
          const link = document.createElement('a')
          const pdf_url = URL.createObjectURL(res.data)
          link.setAttribute('href', pdf_url)
          link.setAttribute('download', this.pdfFilename)
          link.style.visibility = 'hidden'
          link.id = 'downloadPDF'
          document.body.appendChild(link)
          document.getElementById(link.id).click()

          // remove to make sure a new csv file will be downloaded and not the previous one
          const element = document.getElementById(link.id)
          element.remove()
        })
      this.downloading = false
    },
    downloadPDF() {
      this.$refs.html2Pdf.generatePdf()
    },
    amountInBTC(valueInEuro, btcRate) {
      if (this.isAnulat) return false
      const btc = valueInEuro / btcRate
      const btcArr = btc.toString().split('.')
      const amountInBTC = `${btcArr[0]}.${btcArr[1].slice(0, 8)}`
      return amountInBTC
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.invoice-details-logo {
  width: 100%;
  max-width: 25rem;
}

.invoice-to-address {
  max-width: 20rem;
  line-height: 24px;
}

.invoice-period {
  padding: 10px 29px;
  border-top: 1px solid #e7e7e7;
  font-weight: 500;
  font-size: 14px;
  margin-top: -1rem;
}

.paid-style {
  color: #189550;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

.customTable{
    width: 100%;
}

.customTable tr th, .customTable tr td {
    border: none;
    text-align: left;
    padding:8px;
    border: 1px solid #cccccc
}
.leftcell{
    width: 180px;
}
.note{
    font-size:medium;
    padding: 15px 0px 5px;
    color:red;
}

.modal .modal-header .close{
  margin: 2px
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .iban_code,
  .btc_address{
    display: block;
    overflow-wrap: break-word;
    width: 200px
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {
  .iban_code,
  .btc_address{
    display: block;
    overflow-wrap: break-word;
    width: 190px
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {
  .iban_code,
  .btc_address{
    display: block;
    overflow-wrap: break-word;
    width: 180px
  }
}

/* smaller phone */
@media only screen and (max-width: 365px)  {
  .iban_code,
  .btc_address{
    display: block;
    overflow-wrap: break-word;
    width: 180px
  }
}
</style>
